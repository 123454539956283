/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import { useEffect, useRef, useState, CSSProperties } from 'react';
import styles from './Home.module.scss';

import VideoModal from '../components/VideoModal';
import ShopModal from '../components/ShopModal';
import TopMenu from '../components/TopMenu';
import Button from '../components/Button';
import Title from '../components/Title';
import MissionBody from '../components/MissionBody';
import Footer from '../components/Footer';
import FilmTabs from '../components/FilmTabs';
import ImageCard from '../components/ImageCard';
import CustomVideo from '../components/CustomVideo';
import ModalButton from '../components/ModalButton';

import useMediaQuery from '../lib/useMediaQuery';

import buttonItems from '../data/buttonItems';
import missionData from '../data/missionData.json';
import breakpoints from '../data/breakpoints';

// import videoBackgroundDesktop from '../public/videos/nur-grocery-two-desktop-loop.mp4';
// import videoBackgroundMobile from '../public/videos/nur-grocery-two-mobile-loop.mp4';

import { MOBILE_BACKGROUND_VIDEO_HEIGHT } from '../lib/constants';
import filmData from '../data/filmPageData';

interface CustomCSSProps extends CSSProperties {
  '--top': string;
}

const DEFAULT_TOP = '400px';

function Home(): JSX.Element {
  const videoModalRef = useRef<HTMLDivElement | null>(null);
  const shopModalRef = useRef<HTMLDivElement | null>(null);
  const buttonContainerRef = useRef<HTMLDivElement | null>(null);
  const isDesktop = useMediaQuery(breakpoints.desktop);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentModalType, setCurrentModalType] = useState('');
  const [navbarIsOpen, setNavbarIsOpen] = useState(false);
//  const [videoBackground, setVideoBackground] = useState<string | null>(null);
  const [styleObject, setStyleObject] = useState<CustomCSSProps>({
    '--top': DEFAULT_TOP,
  });
  const [dataIndex, setDataIndex ] = useState(0)

/*  useEffect(() => {
    setVideoBackground(isDesktop ? videoBackgroundDesktop : videoBackgroundMobile);
  }, [isDesktop]);*/

  useEffect(() => {
    // This logic is used only for mobile. Mobile values are overwritten for desktop.
    if (buttonContainerRef && buttonContainerRef.current) {
      const { innerHeight } = window;
      setStyleObject({
        '--top': `calc(${
          innerHeight < MOBILE_BACKGROUND_VIDEO_HEIGHT
            ? innerHeight
            : MOBILE_BACKGROUND_VIDEO_HEIGHT
        }px - 150px)`,
      });
    } else {
      setStyleObject({
        '--top': DEFAULT_TOP,
      });
    }
  }, [buttonContainerRef]);

  function handleToggle(): void {
    // Need to do this before toggling. We are closing the modal here.
    // Need to reset the modal type.
    if (modalIsOpen) {
      setCurrentModalType('');
    }
    // Long winded way of avoiding ! on a state variable.
    const currentModalOpen = modalIsOpen;
    const isModalOpen = !currentModalOpen;

    setModalIsOpen(isModalOpen);
  }

  function handleSetIsNavbarOpen(isNavbarOpen: boolean): void {
    setNavbarIsOpen(isNavbarOpen);
  }

  function handleSetCurrentModalType(destination: string): void {
    setCurrentModalType(destination);
  }

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent): void => {
      const ref = currentModalType === 'video' ? videoModalRef : shopModalRef;
      // If the menu is open and the clicked target is not within the modal,
      // then close the modal
      // Hack to avoid modal closing when click on scroll bar -
      // e.target.parentElement is null because it is an html element.
      if (
        modalIsOpen &&
        (e.target as Node).parentElement !== null &&
        ref.current &&
        !ref.current.contains(e.target as Node)
      ) {
        handleToggle();
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside, { passive: true });

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [modalIsOpen]);

  useEffect(() => {
    if (currentModalType !== '') {
      handleToggle();
    }
  }, [currentModalType]);

  return (
    <div className={modalIsOpen ? styles.containerWithModal : styles.container}>
      <div className={styles.videoContainer}>
        <TopMenu handleSetIsNavbarOpen={handleSetIsNavbarOpen} />
        {(!navbarIsOpen || isDesktop) && (
          <>
            <CustomVideo dataIndex={dataIndex} />
            <div ref={buttonContainerRef} style={styleObject} className={styles.buttonContainer}>
              {buttonItems.map(({ label, icon, destination }) => (
                <Button
                  key={label}
                  label={label}
                  icon={icon}
                  destination={destination}
                  handleSetCurrentModalType={handleSetCurrentModalType}
                />
              ))}
                <ModalButton
                  label="VISIT BODEGA"
                  destination={filmData[dataIndex].destination}
                />
            </div>
          </>
        )}
        {modalIsOpen && (
          <div className={styles.modalContainer}>
            <button
              aria-label="close modal"
              type="button"
              className={styles.closeButton}
              onClick={handleToggle}
            >
              <svg
                width="23"
                height="23"
                viewBox="0 0 23 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.40905 11.4999L0 20.9091L2.09088 23L11.4999 13.5908L20.909 23L22.9999 20.9091L13.5908 11.4999L22.9997 2.09091L20.9088 0L11.4999 9.409L2.09106 0L0.000183902 2.09091L9.40905 11.4999Z"
                  fill="white"
                />
              </svg>
            </button>
            {currentModalType === 'video' && (
              <VideoModal modalRef={videoModalRef} show={modalIsOpen} dataIndex={dataIndex} />
            )}
            {currentModalType === 'shop' && (
              <ShopModal modalRef={shopModalRef} show={modalIsOpen} dataIndex={dataIndex} />
            )}
          </div>
        )}
      </div>
      {(!navbarIsOpen || isDesktop) && (
        <>
          <FilmTabs setDataIndex={setDataIndex} dataIndex={dataIndex} />
          <ImageCard dataIndex={dataIndex} />
          <div className={styles.missionContainer}>
            <div className={styles.missionTitle}>
              <Title title='Our Mission.' styleClass='titleMission' />
            </div>
            <div className={styles.missionContent}>
              {missionData.map(({ id, paragraph, topMargin }) => (
                <MissionBody
                  key={id}
                  text={paragraph}
                  styleClass={topMargin ? 'bodyTopMargin' : 'bodyNoMargin'} />
              ))}
            </div>
          </div>
        </>
      )}
      {(!navbarIsOpen || isDesktop) && <Footer />}
    </div>
  );
}

export default Home;
