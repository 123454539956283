import styles from './Subtitle.module.scss';

type subtitleProps = {
  subtitle: string;
  styleClass: string;
  margin?: string;
};

type styleObjectProps = {
  margin?: string;
};

const defaultProps = {
  margin: undefined,
};

function Subtitle({ subtitle, styleClass, margin }: subtitleProps): JSX.Element {
  const styleObject = {} as styleObjectProps;
  if (margin) {
    styleObject.margin = margin;
  }

  return (
    <h2 style={styleObject} className={styles[styleClass]}>
      {subtitle}
    </h2>
  );
}

Subtitle.defaultProps = defaultProps;

export default Subtitle;
