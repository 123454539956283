import styles from './Button.module.scss';

type ButtonProps = {
  label: string;
  icon: boolean;
  destination: string;
  handleSetCurrentModalType?: (destination: string) => void;
};

const defaultProps = {
  handleSetCurrentModalType: undefined,
};

function Button({ label, icon, destination, handleSetCurrentModalType }: ButtonProps): JSX.Element {
  function handleClick(): void {
    if (handleSetCurrentModalType) {
      handleSetCurrentModalType(destination);
    } else {
      window.location.href = destination;
    }
  }

  return (
    <button
      aria-labelledby={label}
      type="button"
      className={icon ? styles.iconButton : styles.button}
      onClick={handleClick}
    >
      <span>{label}</span>
      {icon ? (
        <svg
          width="36"
          height="35"
          viewBox="0 0 36 35"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <ellipse cx="18" cy="17.5" rx="18" ry="17.5" fill="#111111" />
          <path d="M15 22.59L19.58 18L15 13.41L16.41 12L22.41 18L16.41 24L15 22.59Z" fill="white" />
        </svg>
      ) : (
        ''
      )}
    </button>
  );
}

Button.defaultProps = defaultProps;

export default Button;
