import styles from './ModalButton.module.scss';

type ModalButtonProps = {
  label: string;
  destination: string;
};

function ModalButton({ label, destination }: ModalButtonProps): JSX.Element {
  function handleClick(): void {
    // window.location.href = destination;
    window.open(destination, '_blank');
  }

  return (
    <button aria-labelledby={label} type="button" className={styles.button} onClick={handleClick}>
      <span>{label}</span>
    </button>
  );
}

export default ModalButton;
