import nur_thumbnail from '../public/images/Nur.png'
import manny_thumbnail from '../public/images/Manny.png'
import broadway_thumbnail from '../public/images/Broadway.png'

import nur_1 from '../public/images/nur/nur-1.jpg'
import nur_2 from '../public/images/nur/nur-2.jpg'
import nur_3 from '../public/images/nur/nur-3.jpg'
import nur_4 from '../public/images/nur/nur-4.jpg'
import nur_5 from '../public/images/nur/nur-5.jpg'

import detox_1 from '../public/images/detox/detox-1.jpg'
import detox_2 from '../public/images/detox/detox-2.jpg'
import detox_3 from '../public/images/detox/detox-3.jpg'
import detox_4 from '../public/images/detox/detox-4.jpg'
import detox_5 from '../public/images/detox/detox-5.jpg'

import broadway_1 from '../public/images/broadway/broadway-1.jpg'
import broadway_2 from '../public/images/broadway/broadway-2.jpg'
import broadway_3 from '../public/images/broadway/broadway-3.jpg'
import broadway_4 from '../public/images/broadway/broadway-4.jpg'
import broadway_5 from '../public/images/broadway/broadway-5.jpg'

import nur_desktop_vid from '../public/videos/nur-grocery-two-desktop-loop.mp4'
import nur_mobile_vid from '../public/videos/nur-grocery-two-mobile-loop.mp4'
import nur_vid from '../public/videos/nur-grocery-two.mp4'

import detox_desktop_vid from '../public/videos/manny-detox-desktop-loop.mp4'
import detox_mobile_vid from '../public/videos/manny-detox-mobile-loop.mp4'
import detox_vid from '../public/videos/manny-detox.mp4'

import broadway_desktop_vid from '../public/videos/broadway-food-desktop-loop.mp4'
import broadway_mobile_vid from '../public/videos/broadway-food-mobile-loop.mp4'
import broadway_vid from '../public/videos/broadway-food.mp4'

import nur_vid_image from '../public/images/nur/nur-vid-placeholder.jpg'
import manny_vid_image from '../public/images/detox/detox-vid-placeholder.jpg'
import broadway_vid_image from '../public/images/broadway/broadway-vid-placeholder.jpg'

import nur_vid_caption from '../public/files/nur-grocery-two-captions.vtt'
import manny_vid_caption from '../public/files/mannys-captions.vtt'
import broadway_vid_caption from '../public/files/broadway-captions.vtt'

import nur_bag from '../public/images/bodega-bag-back.jpg'
import detox_bag from '../public/images/bodega-bag-back.jpg'
import broadway_bag from '../public/images/bodega-bag-back.jpg'
import front_view from '../public/images/bodega-bag-front.jpg'

// don't have looping videos for broadway food or manny's detox yet so just re-using nur grocery two content for the time being


const filmData = [
  {
    id: 0,
    title: "Nur Grocery Two",
    thumbnail: nur_thumbnail,
    video: nur_vid,
    desktop_vid: nur_desktop_vid,
    mobile_vid: nur_mobile_vid,
    vid_image: nur_vid_image,
    vid_caption: nur_vid_caption,
    tote_bag_front: front_view,
    tote_bag_back: nur_bag,
    merch_text: "Love supporting local businesses even more? and pick up a limited edition tote bag with all proceeds supporting Nur Grocery 2 and its owners.",
    destination: "https://www.google.com/maps/place/54+Avenue+C,+New+York,+NY+10009/@40.7222699,-73.9804441,19z/data=!3m1!4b1!4m5!3m4!1s0x89c259790175f92d:0xf62e66fbfc3d31f1!8m2!3d40.7222699!4d-73.9798969",
    items: [
      { path: nur_1, alt: "one" },
      { path: nur_2, alt: "two" },
      { path: nur_3, alt: "three" },
      { path: nur_4, alt: "four" },
      { path: nur_5, alt: "five" }
    ],
  },
  {
    id: 1,
    title: "Broadway Food Mart",
    thumbnail: broadway_thumbnail,
    video: broadway_vid,
    desktop_vid: broadway_desktop_vid,
    mobile_vid: broadway_mobile_vid,
    vid_image: broadway_vid_image,
    vid_caption: broadway_vid_caption,
    tote_bag_front: front_view,
    tote_bag_back: broadway_bag,
    merch_text: "Love supporting local businesses even more? and pick up a limited edition tote bag with all proceeds supporting Broadway Food Mart and its owners.",
    destination: "https://www.google.com/maps/place/Broadway+Food+mart+%E9%99%B3%E8%B1%86%E8%8A%B1%E6%89%8B%E5%B7%A5%E8%B1%86%E8%8A%B1%E5%BA%97/@40.7405856,-73.8817231,17z/data=!3m1!4b1!4m5!3m4!1s0x89c25fad2dcdcecf:0x42035719b7845b1d!8m2!3d40.7405856!4d-73.8795344",
    items: [
      { path: broadway_1, alt: "one" },
      { path: broadway_2, alt: "two" },
      { path: broadway_3, alt: "three" },
      { path: broadway_4, alt: "four" },
      { path: broadway_5, alt: "five" }
    ],
  },
  {
    id: 2,
    title: "Manny's Detox",
    thumbnail: manny_thumbnail,
    video: detox_vid,
    desktop_vid: detox_desktop_vid,
    mobile_vid: detox_mobile_vid,
    vid_image: manny_vid_image,
    vid_caption: manny_vid_caption,
    tote_bag_front: front_view,
    tote_bag_back: detox_bag,
    merch_text: "Love supporting local businesses even more? and pick up a limited edition tote bag with all proceeds supporting Manny's Detox and its owners.",
    destination: "https://www.google.com/maps/place/Manny%27s+Detox+Juice+Bar+and+Grill/@40.7053168,-73.9448239,17z/data=!3m1!4b1!4m5!3m4!1s0x89c25bfc0f7af329:0xe0b501f3948141e5!8m2!3d40.7052987!4d-73.9426702",
    items: [
      { path: detox_1, alt: "one" },
      { path: detox_2, alt: "two" },
      { path: detox_3, alt: "three" },
      { path: detox_4, alt: "four" },
      { path: detox_5, alt: "five" }
    ],
  },
];

export default filmData