import { Route, Switch } from 'react-router-dom';

import Home from './containers/Home';

import routes from './data/routes';

function App(): JSX.Element {
  return (
    <>
      <div>
        <Switch>
          {routes.map(({ path, exact, component: C }) => (
            <Route key={path} path={path} exact={exact} component={C} />
          ))}
          <Route path="*">
            <Home />
          </Route>
        </Switch>
      </div>
    </>
  );
}

export default App;
