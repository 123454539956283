import styles from './MissionBody.module.scss';

type missionBpdyProps = {
  text: string;
  styleClass: string;
};

function MissionBody({ text, styleClass }: missionBpdyProps): JSX.Element {
  return <p className={styles[styleClass]}>{text}</p>;
}

export default MissionBody;
