import styles from './FilmTabs.module.scss';

import { Dispatch, SetStateAction, MouseEvent } from 'react';

import filmData from '../../data/filmPageData';

type FilmTabsProps = {
  setDataIndex: Dispatch<SetStateAction<number>> | null;
  dataIndex: number;
}

function FilmTabs({setDataIndex, dataIndex}: FilmTabsProps): JSX.Element {

  function onClickHandler(event: MouseEvent): void {
    if (setDataIndex) {
      setDataIndex(parseInt((event.currentTarget as HTMLElement)?.getAttribute('data-id') || '0'));
    }
  }

  return <div className={styles.FilmTabs}>
    {filmData.map(({ id, title, thumbnail }) => (
      <button key={id} data-id={id} onClick={onClickHandler} className={styles.Preview} style={
        dataIndex === id
          ? {backgroundColor: '#FF7A0F'}
          : {backgroundColor: '#323232'}
      }>
        <div className={styles.Thumbnail}>
          <img src={thumbnail} />
        </div>
        <span className={styles.tabText}>{title}</span>
      </button>
    ))}
  </div>;
}

export default FilmTabs;