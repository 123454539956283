import { useState } from 'react';
import styles from './ShoutOuts.module.scss';

import TopMenu from '../components/TopMenu';
import Title from '../components/Title';
import Subtitle from '../components/Subtitle';
import Body from '../components/Body';
import Footer from '../components/Footer';

import useMediaQuery from '../lib/useMediaQuery';

import shoutOutsData from '../data/shoutOutsData.json';
import breakpoints from '../data/breakpoints';

function ShoutOuts(): JSX.Element {
  const isDesktop = useMediaQuery(breakpoints.desktop);

  const [navbarIsOpen, setNavbarIsOpen] = useState(false);

  function handleSetIsNavbarOpen(isNavbarOpen: boolean): void {
    setNavbarIsOpen(isNavbarOpen);
  }

  return (
    <div className={styles.container}>
      <TopMenu handleSetIsNavbarOpen={handleSetIsNavbarOpen} />
      <div className={styles.shoutOuts}>
        <div className={styles.shoutOutTitle}>
          <Title title="Shout Outs" styleClass="titleShoutOut" />
        </div>
        {(!navbarIsOpen || isDesktop) && (
          <div className={styles.shoutOutContainer}>
            {shoutOutsData.map(({ id, subtitle, paragraph, author }) => (
              <div className={styles.shoutOutContent} key={id}>
                <Subtitle subtitle={subtitle} styleClass="subtitleLight" />
                <Body text={paragraph} styleClass="bodyLight" />
                <Body text={author} styleClass="bodyLight" />
              </div>
            ))}
          </div>
        )}
      </div>
      {(!navbarIsOpen || isDesktop) && <Footer />}
    </div>
  );
}

export default ShoutOuts;
