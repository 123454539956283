import styles from './Body.module.scss';

type BodyProps = {
  text: string;
  styleClass: string;
  margin?: string;
};

type styleObjectProps = {
  margin?: string;
};

const defaultProps = {
  margin: undefined,
};

function Body({ text, styleClass, margin }: BodyProps): JSX.Element {
  const styleObject = {} as styleObjectProps;
  if (margin) {
    styleObject.margin = margin;
  }

  return (
    <p style={styleObject} className={styles[styleClass]}>
      {text}
    </p>
  );
}

Body.defaultProps = defaultProps;

export default Body;
