/* eslint-disable max-len */
import { MutableRefObject } from 'react';

import styles from './ShopModal.module.scss';

import Subtitle from '../Subtitle/Subtitle';
import Body from '../Body/Body';
import ModalButton from '../ModalButton/ModalButton';

import filmData from '../../data/filmPageData';

type ShopModalProps = {
  modalRef: MutableRefObject<HTMLDivElement | null>;
  show: boolean;
  dataIndex: number;
};

function ShopModal({ modalRef, show, dataIndex }: ShopModalProps): JSX.Element {

  return (
    <div ref={modalRef} className={show ? styles.modal : styles.closed}>
      <div className={styles.container}>
        <div className={styles.imageWrapper}>
          <img className={styles.imageBottom} src={filmData[dataIndex].tote_bag_back} alt="back view of tote bag" />
          <img className={styles.imageTop} src={filmData[dataIndex].tote_bag_front} alt="front view of tote bag" />
        </div>
        <div className={styles.contentWrapper}>
          <Subtitle subtitle="Love tote bags?" styleClass="subtitleDark" margin="18px 0 0 0" />
          <Body text={filmData[dataIndex].merch_text} styleClass="bodyDark" margin="18px 0 21px 0" />
          <ModalButton
            label="GET DIRECTIONS"
            destination={filmData[dataIndex].destination}
          />
        </div>
      </div>
    </div>
  );
}

export default ShopModal;
