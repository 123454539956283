import styles from './CustomVideo.module.scss';
import filmData from '../../data/filmPageData';
import useMediaQuery from '../../lib/useMediaQuery';
import breakpoints from '../../data/breakpoints';
import { useEffect, useRef, useState } from 'react';

type customVideoProps = {
  dataIndex: number;
}

function CustomVideo({dataIndex}: customVideoProps): JSX.Element {

  const [videoBackground, setVideoBackground] = useState<string | null>(null);

  const videoRef = useRef<HTMLVideoElement>(null);

  const isDesktop = useMediaQuery(breakpoints.desktop);

  useEffect(() => {
    setVideoBackground(filmData[dataIndex].desktop_vid);
    videoRef.current?.load();
  }, [dataIndex]);

  useEffect(() => {
    setVideoBackground(isDesktop ? filmData[dataIndex].desktop_vid : filmData[dataIndex].mobile_vid);
  }, [isDesktop]);

  return ( {isDesktop} && (<> {videoBackground && (<video className={styles.CustomVideo} autoPlay loop muted playsInline ref={videoRef}>
    <source src={isDesktop ? filmData[dataIndex].desktop_vid : filmData[dataIndex].mobile_vid}
            type="video/mp4"
    />
    Your browser does not support embedded videos.
  </video> )} </> ))
}

export default CustomVideo;