/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useRef, useEffect, CSSProperties } from 'react';
import { NavLink } from 'react-router-dom';

import styles from './CustomNavLink.module.scss';

type CustomNavLinkProps = {
  name: string;
  param: string;
  currentRoute: string;
  isDesktop: boolean;
  handleCustomRoute: (param: string) => void;
};

interface CustomCSSProps extends CSSProperties {
  '--width': string;
  '--maxWidth': string;
  '--pointerEvents' : string;
}

function CustomNavLink({
  name,
  param,
  isDesktop,
  currentRoute,
  handleCustomRoute,
}: CustomNavLinkProps): JSX.Element {
  const ref = useRef<HTMLAnchorElement | null>(null);

  const [styleObject, setStyleObject] = useState<CustomCSSProps>({
    '--width': '30px',
    '--maxWidth': '100%',
    '--pointerEvents': 'auto'
  });

  useEffect(() => {
    if (ref && ref.current) {
      const maxWidth = `${ref.current.offsetWidth}px`;
      setStyleObject({
        '--width': currentRoute === `/${param}` ? maxWidth : '30px',
        '--maxWidth': maxWidth,
        '--pointerEvents': currentRoute === `/${param}` && isDesktop ? 'none' : 'auto',
      });
    }
  }, [ref, isDesktop]);

  function handleOnClick(): void {
    handleCustomRoute(`/${param}`);
  }

  return (
    <>
      <NavLink style={styleObject} onClick={handleOnClick} className={styles.NavLink} to={param}>
        <span ref={ref}>{name}</span>
      </NavLink>
    </>
  );
}

export default CustomNavLink;
