const buttonItems = [
  {
    label: 'WATCH FILM',
    icon: true,
    destination: 'video',
  },
/*  {
    label: 'BUY MERCH',
    icon: false,
    destination: 'shop',
  },*/
];

export default buttonItems;
