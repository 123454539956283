import styles from './ImageCard.module.scss';

import nyc from '../../public/images/NYC.png';

import filmData from '../../data/filmPageData';

type imageCardProps = {
  dataIndex: number;

}

function ImageCard({dataIndex}: imageCardProps): JSX.Element {
  return <div className={styles.ImageCard}>
    <h2 className={styles.Title}>{filmData[dataIndex].title}</h2>
    <div className={styles.Icon}>
      <img src={nyc}/>
    </div>
    <div className={styles.imageContainer}>
      {filmData[dataIndex].items.map(({ path, alt }, index) => (
        <img key={index} className={styles.image} src={path} alt={alt} />
      ))}
    </div>
  </div>
}

export default ImageCard;