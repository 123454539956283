import styles from './Title.module.scss';

type TitleProps = {
  title: string;
  styleClass: string;
};

function Title({ title, styleClass }: TitleProps): JSX.Element {
  return <h1 className={styles[styleClass]}>{title}</h1>;
}

export default Title;
