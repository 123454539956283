import { useState, useEffect, useRef, MutableRefObject } from 'react';
import styles from './VideoModal.module.scss';

import useMediaQuery from '../../lib/useMediaQuery';

import breakpoints from '../../data/breakpoints';
import filmData from '../../data/filmPageData';

type VideoModalProps = {
  modalRef: MutableRefObject<HTMLDivElement | null>;
  show: boolean;
  dataIndex: number;
};

function VideoModal({ modalRef, show, dataIndex }: VideoModalProps): JSX.Element {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const isDesktop = useMediaQuery(breakpoints.desktop);

  function handleClick(): void {
    if (videoRef && videoRef.current) {
      videoRef.current.play();
      videoRef.current.controls = true;
      setIsVideoPlaying(true);
    }
  }

  useEffect(() => {
    const playButtonDiv = document.getElementById(
      isDesktop ? 'playButtonDesktop' : 'playButtonMobile'
    );

    if (playButtonDiv) {
      playButtonDiv.addEventListener('click', handleClick, { passive: true });
    }

    return () => {
      // Cleanup the event listener
      if (playButtonDiv) {
        playButtonDiv.removeEventListener('click', handleClick);
      }
    };
  }, [isDesktop]);

  return (
    <div ref={modalRef} className={show ? styles.modal : styles.closed}>
      <div className={styles.videoWrapper}>
        <video
          ref={videoRef}
          className={styles.video}
          poster={filmData[dataIndex].vid_image}
          controlsList="nodownload"
          playsInline
        >
          <track kind="captions" src={filmData[dataIndex].vid_caption} label="English" srcLang="en-us" default />
          <source src={filmData[dataIndex].video} type="video/mp4" />
          Your browser does not support embedded videos.
        </video>
        {!isVideoPlaying && (
          <div className={styles.buttonWrapper}>
            {isDesktop ? (
              <svg
                id="playButtonDesktop"
                className={styles.button}
                width="148"
                height="148"
                viewBox="0 0 148 148"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g filter="url(#filter0_d_794_906)">
                  <circle opacity="0.9" cx="70" cy="70" r="54" fill="white" />
                  <path d="M63.5063 49.4939L84.0124 70L63.5063 90.5061V49.4939Z" fill="#222222" />
                </g>
                <defs>
                  <filter
                    id="filter0_d_794_906"
                    x="0"
                    y="0"
                    width="148"
                    height="148"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dx="4" dy="4" />
                    <feGaussianBlur stdDeviation="10" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_794_906"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_794_906"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
            ) : (
              <svg
                id="playButtonMobile"
                className={styles.button}
                width="130"
                height="130"
                viewBox="0 0 130 130"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g filter="url(#filter0_d_794_906)">
                  <circle opacity="0.9" cx="61" cy="61" r="45" fill="white" />
                  <path d="M55.5887 43.9116L72.6771 61L55.5887 78.0884V43.9116Z" fill="#222222" />
                </g>
                <defs>
                  <filter
                    id="filter0_d_794_906"
                    x="0"
                    y="0"
                    width="130"
                    height="130"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dx="4" dy="4" />
                    <feGaussianBlur stdDeviation="10" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_794_906"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_794_906"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default VideoModal;
