import { useState, useRef, useEffect, CSSProperties } from 'react';
import { useHistory } from 'react-router';

import CustomNavLink from '../CustomNavLink';
import Footer from '../Footer';

import styles from './TopMenu.module.scss';

import useMediaQuery from '../../lib/useMediaQuery';

import navItems from '../../data/navItems';
import breakpoints from '../../data/breakpoints';

import logo from '../../public/images/logo.png';

type TopMenuProps = {
  handleSetIsNavbarOpen?: (isNavbarOpen: boolean) => void;
};

interface CustomCSSProps extends CSSProperties {
  '--navHeight': string;
}

const defaultProps = {
  handleSetIsNavbarOpen: undefined,
};

const DEFAULT_VIEW_HEIGHT = '100vh';

function TopMenu({ handleSetIsNavbarOpen }: TopMenuProps): JSX.Element {
  const navRef = useRef<HTMLDivElement | null>(null);
  const history = useHistory();
  const isDesktop = useMediaQuery(breakpoints.desktop);

  const [navbarOpen, setNavbarOpen] = useState(false);

  const [styleObject, setStyleObject] = useState<CustomCSSProps>({
    '--navHeight': DEFAULT_VIEW_HEIGHT,
  });

  useEffect(() => {
    if (navRef && navRef.current) {
      // browserSpace = viewPortHeight - windowHeight
      const browserSpace = navRef.current.getBoundingClientRect().height - window.innerHeight;
      setStyleObject({
        '--navHeight': `calc(100vh - ${browserSpace}px)`,
      });
    } else {
      setStyleObject({
        '--navHeight': DEFAULT_VIEW_HEIGHT,
      });
    }
  }, [navRef, navbarOpen]);

  function handleToggle(): void {
    // Long winded way of avoiding ! on a state variable.
    const currentNavbarOpen = navbarOpen;
    const isNavbarOpen = !currentNavbarOpen;

    setNavbarOpen(isNavbarOpen);

    if (handleSetIsNavbarOpen) {
      handleSetIsNavbarOpen(isNavbarOpen);
    }
  }

  function handleCustomRoute(param: string): void {
    // Covers the edge case when user clicks on menu item when on the same page.
    // Closes the navbar.
    if (history.location.pathname === param) {
      handleToggle();
    }
  }

  return (
    <div className={navbarOpen ? styles.TopMenuActive : styles.TopMenu}>
      <button
        aria-label="menu"
        type="button"
        onClick={handleToggle}
        className={styles.TopMenuButton}
      >
        <div className={styles.icon}>
          <span />
          <span />
          <span />
        </div>
      </button>
      <img className={styles.logo} src={logo} alt="logo" />
      {(isDesktop || navbarOpen) && (
        <div ref={navRef} style={styleObject} className={styles.Nav}>
          <div className={styles.BtnWrapper}>
            {navItems.map(({ name, param }) => (
              <CustomNavLink
                key={name}
                name={name}
                param={param}
                isDesktop={isDesktop}
                currentRoute={history.location.pathname}
                handleCustomRoute={handleCustomRoute}
              />
            ))}
          </div>
          {navbarOpen && <Footer />}
        </div>
      )}
    </div>
  );
}

TopMenu.defaultProps = defaultProps;

export default TopMenu;
