import Home from '../containers/Home';
import ShoutOuts from '../containers/ShoutOuts';

const routes = [
  {
    path: '/',
    exact: true,
    component: Home,
  },
  {
    path: '/films',
    component: Home,
  },
  {
    path: '/shoutouts',
    component: ShoutOuts,
  },
];

export default routes;
