const navItems = [
  {
    name: 'THE FILMS',
    param: 'films',
  },
  {
    name: 'SHOUT OUTS',
    param: 'shoutouts',
  },
];

export default navItems;
