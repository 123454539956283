import { useState, useEffect } from 'react';

function useMediaQuery(query: string): boolean {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia(query);

    if (mediaQuery.matches !== matches) {
      setMatches(mediaQuery.matches);
    }

    const listener = (): void => {
      setMatches(mediaQuery.matches);
    };

    // Had to add this check for compatibility with older browsers.
    // Even though addListener is deprecated. E.g. IOS 13 Safari.
    if (mediaQuery?.addEventListener) {
      mediaQuery.addEventListener('change', listener, { passive: true });
    } else {
      mediaQuery.addListener(listener);
    }

    return () => {
      if (mediaQuery?.removeEventListener) {
        mediaQuery.removeEventListener('change', listener);
      } else {
        mediaQuery.removeListener(listener);
      }
    };
  }, [matches, query]);

  return matches;
}

export default useMediaQuery;
